// App.js
import React, { useRef } from 'react';
import NavBar from './Components/NavBar';
import Services from './Components/Services';
import About from './Components/About';
import Contact from './Components/Contact';
import Partner from './Components/Partners';
import File from './Components/File';
import Footer from './Components/Footer';
import ToolApp from './Components/ToolTip';
import Number from './Components/Number';

function App() {
    // Define refs for Services, About, Contact, and Partners components
        const servicesRef = useRef(null);
        const aboutRef = useRef(null);
        const contactRef = useRef(null);
        const partnerRef = useRef(null);

        // Function to scroll to the next component
        const scrollToNext = (ref) => {
        if (ref && ref.current) {
            const yOffset = -80; // Adjust this value as needed to align with the top of the viewport
            const y = ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            // Close the mobile menu after clicking on a link
        }
        };

    return (
        <div className="App">
            {/* Render the NavBar */}
            <NavBar servicesRef={servicesRef} aboutRef={aboutRef} contactRef={contactRef} partnerRef={partnerRef} />

            {/* Render the Video component */}
            <div className="fixed inset-0 z-0 ">
                <File style={{ width: '100%', height: '100%' }} />
            </div>

            {/* Render text overlay */}
            <div className=''>
            <div className="relative mt-52 top-0 left-0 z-20 items-center justity-center text-center text-white ">
                <div className="md:text-6xl text-4xl font-semibold">PIONEERING INDIA'S</div>
                <div className="mt-4 md:text-6xl text-4xl font-semibold">NEO-URBAN INFRASTRUCTURE</div>
                <button onClick={() => scrollToNext(servicesRef, -80)}>
                    <img
                        alt="Arrow Down"
                        width="55"
                        height="31"
                        src="https://static.wixstatic.com/media/c19c76_376dc1d20bd348b99c4a732a1d6d7c81.png"
                        className="w-55 h-31 object-cover mt-10"
                    />
                </button>
            </div>
            </div>

            {/* Render the other components below the video */}
            <div className="absolute z-10 ">
                <div className='mt-96'>
                <Services id="project" servicesRef={servicesRef} /></div>
                <About id="about" aboutRef={aboutRef} scrollToNext={scrollToNext} />
                <Number/>
                <Partner partnerRef={partnerRef} scrollToNext={scrollToNext} />
                <div><Contact id="contact" contactRef={contactRef} /></div>
                <ToolApp />
                <Footer />
            </div>
        </div>
    );
}

export default App;
